// https://master--apollo-client-docs.netlify.app/docs/react/data/subscriptions/
import React, {useState, useEffect} from 'react'
import {useQuery, useSubscription, useApolloClient, gql} from '@apollo/client'
import {intervalToDuration, addMinutes} from 'date-fns'
import {useNavigate} from "react-router-dom"
import ChatDel from './ChatDel'

import Bounce from 'react-reveal/Bounce'

import {ReactComponent as JoinedSvg} from '../lib/svg/joined.svg'
import {ReactComponent as TicketsSvg} from '../lib/svg/tickets.svg'
import {ReactComponent as WinnerSvg} from '../lib/svg/winner.svg'
import {ReactComponent as AdminSvg} from '../lib/svg/avatars/admin.svg'
import {ReactComponent as BoySvg} from '../lib/svg/avatars/boy.svg'
import {ReactComponent as GirlSvg} from '../lib/svg/avatars/girl.svg'
import {ReactComponent as AnonSvg} from '../lib/svg/avatars/anon.svg'
import {ReactComponent as LoginSvg} from '../lib/svg/login.svg'
import {ReactComponent as ChatSvg}  from '../lib/svg/chat.svg'
import {ReactComponent as ChatedSvg}  from '../lib/svg/chated.svg'
import {ReactComponent as RemoveSvg}  from '../lib/svg/remove.svg'
import {ReactComponent as CreatedSvg}  from '../lib/svg/created.svg'

const GET_CHAT = gql`
      query Chat {
        chats {
          id
          created
          message 
          deleted
          user
          login {
            gender
            created
            lastdate
            tickets{
              id
            }
            chated{
              id
            }
            isOnline
          }
        }
      }`;

const SUB_CHAT = gql`
subscription subChat {
  subChat {
    message 
  }
}`;

      const timeSince = (date, now) => {
        const interval = intervalToDuration({
          start: new Date(date),
          end: addMinutes(now, 180)
        })
        if(interval.months){
          return interval.months + " мес" 
        }
        else if(interval.days){
          return interval.days + " дн"   
        }
        else if(interval.hours){
          return interval.hours + " час"   
        }
        else if(interval.minutes){
          return interval.minutes + " мин"   
       }
      }
  
      const delChat = (deleted) => {
        const interval = intervalToDuration({
          start: addMinutes(new Date(), 180),
          end: new Date(deleted)
        })
        if(interval.months){
          return interval.months + " мес" 
        }
        else if(interval.days){
          return interval.days + " дн"   
        }
        else if(interval.hours){
          return interval.hours + " час"   
        }
        else if(interval.minutes){
          return interval.minutes + " мин"   
       }
      }



function Chat({thisClass}){
    const client = useApolloClient()
    const loadUser = useNavigate()
    const [del, setDel] = useState(false)
    const [dateNow, setDateNow ] = useState(new Date())
    const [autoDel, setAutoDel] = useState(null)
    const {data, loading, error} = useQuery(GET_CHAT, {
    /*  pollInterval: 60000,
    onCompleted: client.refetchQueries({include: [GET_CHAT]})*/})
    const {data: subscriptionData, loading: subscriptionLoading} =  useSubscription(SUB_CHAT)

    useEffect(() => {
      if(subscriptionData) {
        console.log("SUB")
        client.refetchQueries({include: [GET_CHAT]})
        scrollChat()
      }
    }, [subscriptionData])


    const chatDel = () => {
        console.log("wasDEL((((((((")
        setDel(true)
        setTimeout(() => setDel(false), 2000)
        client.refetchQueries({include: [GET_CHAT]})
    }

    const [list, setList] = useState([]) 
    useEffect(() => {
      if(data) setList(data.chats)
    }, [data])

    useEffect(() => {
      setTimeout(() => scrollChat(), 1000)
      const timer = setInterval(() => {
        client.refetchQueries({include: [GET_CHAT]})
        setDateNow(new Date())
        console.log("refetched")
      }, 60000)
      return () => clearInterval(timer)
    })

    const scrollChat = () => {
      const msgWrap = document.getElementById('msg-wrap')
      msgWrap.scrollTop = 3000
    }


    if (loading) return <div className="loading" />
    if (error) return <pre>{error.message}</pre>

    return(
    <div id="chat" className={thisClass}>
        <Bounce bottom opposite when={del}><span className="error-param">Сообщение удалено</span></Bounce>
        <h2 onClick={scrollChat}>Онлайн-чат<ChatSvg /></h2>
        <section>
        <ul className="msg-wrap" id="msg-wrap">
        {list.map(item => (
        <li key={item.id} className={item.login.isOnline ? "msg online" : "msg"}>
          <figure onClick={() => item.user != 'Anon' ? loadUser(`/user/${item.user}`) : null}>
              {Number(item.login.gender) == 0 && <GirlSvg />}
              {Number(item.login.gender) == 1 && <BoySvg />}
              {Number(item.login.gender) == 2 && <AnonSvg />}
              {Number(item.login.gender) == 3 && <AdminSvg />}
              {item.login.isOnline && <h5>- online -</h5>}
              <div>
                  <ul className="svg-wrap">
                      <li><JoinedSvg /> {(new Date(item.login.created)).getFullYear()}</li>
                      <li><TicketsSvg /> {item.login.tickets.length}</li>
                      <li><ChatedSvg />  {item.login.chated.length}</li>
                  </ul>
                  <figcaption>{item.user.slice(0,9)}{item.user.length > 9 && '..'} <span><CreatedSvg /> ..{timeSince(item.created, dateNow)}</span></figcaption>
              </div>
          </figure> 
          <p>{item.message}</p>
          <ul className={item.deleted ? "opt-wrap deleted" : "opt-wrap"}>
              {item.deleted && <li>Автоудаление: {delChat(item.deleted)}</li>}
              {item.user == "Anon" && <ChatDel del={item.id} />}
              {item.user == localStorage.getItem('login') && <ChatDel chatDel={chatDel} del={item.id} />}
          </ul>
        </li>
        ))}
        </ul>
        </section> 
    </div>
    )
}
export default Chat